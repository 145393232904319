import React from "react";

import Grid from "@mui/material/Grid";
import logoMoni from "../images/logo.png";
import { Button } from "@mui/material";
import { SocialIcon } from "react-social-icons";
import flujoHeader from "../images/flujoHeader.png";
import { MdAddCard } from "react-icons/md";
import { MdCreditCard } from "react-icons/md";
import { MdPublishedWithChanges } from "react-icons/md";
import { MdAttachMoney } from "react-icons/md";
import { MdOutlineSavings } from "react-icons/md";
import visa from "../images/visa.png";
import pagosSeguros from "../images/pagosSeguros.png";
import { GrMail } from "react-icons/gr";
import bloqueo from "../images/bloqueo.png";
import viajes from "../images/viajes.png";
import limites from "../images/limites.png";
import controlComercio from "../images/controlComercios.png";
import movil from "../images/movil.png";
import shopping from "../images/shopping.png";
import seguridad from "../images/seguridad.png";
import laptop from "../images/laptopIcons.png";
import controlGastos from "../images/controlGastos.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Hidden from "@mui/material/Hidden";
import flujoHeaderMobile from "../images/flujoHeaderMobile.png";
import pagosSegurosMobile from "../images/pagosSegurosMobile.png";
import controlGastosMobile from "../images/controlGastosMobile.png";
import controlGastosMobile1 from "../images/controlGastosMobile1.png";
import lystoClassic from "../images/lystoClassic.png";
import lystoGold from "../images/lystoGold.png";
import lystoInfinite from "../images/lystoInfinite.png";
import lystoPlatinum from "../images/lystoPlatinum.png";
import tarjeta from "../images/tarjeta.png";
import plataforma from "../images/plataforma.png";
import procesos from "../images/procesos.png";
import control from "../images/control.png";
import integracion from "../images/integracion.png";
import lystoClassicB from "../images/lystoClassicB.png";
import lystoGoldB from "../images/lystoGoldB.png";
import lystoPlatinumB from "../images/lystoPlatinumB.png";
import lystoInfiniteB from "../images/lystoInfiniteB.png";
import CookieConsent from "react-cookie-consent";
import background from "../images/background.png";
import fondoControl from "../images/controlFondo.png";
import fondoFinal from "../images/fondoFinal.png";
import fondoIntegra from "../images/fondoIntegraciones.png";
import backgroundMobile from "../images/backgroundMobile.png";

const Home = () => {
  const opts = {
    height: "300",
    width: "450",
  };

  const [nombreEmail, setNombreEmail] = React.useState();
  const [emailEmail, setEmailEmail] = React.useState();
  const [nombrePais, setNombrePais] = React.useState();
  const [idCedula, setIdCedula] = React.useState();
  const [direccion, setDireccion] = React.useState();
  const [cantEmpleados, setCantEmpleados] = React.useState();

  const capture = React.useCallback(() => {
    fetch("./mail.php", {
      data: {
        email: emailEmail,
        brandName: nombreEmail,
        country: nombrePais,
        id: idCedula,
        direccion: direccion,
        empleados: cantEmpleados,
      },
    });
  }, [emailEmail, nombreEmail, nombrePais, idCedula, direccion, cantEmpleados]);

  const scrollEffect = document.querySelector(".scroll-effect");
  window.addEventListener("scroll", () => {
    const position = scrollEffect.getBoundingClientRect();
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      scrollEffect.classList.add("active");
    }
  });

  const questions = document.querySelectorAll(".faq-question");

  questions.forEach((question) => {
    question.addEventListener("click", () => {
      const answer = question.nextElementSibling;
      answer.classList.toggle("show");
    });
  });

  return (
    <>
      <header class="header">
        <img
          style={{
            maxWidth: "210px",
            position: "relative",
            bottom: "2px",
            left: "10px",
            /* filter: "drop-shadow(1px 1px 2px black)", */
          }}
          src={logoMoni}
        ></img>

        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn">
          <span class="navicon"></span>
        </label>
        <div class="menu">
          <li>
            <a href="#demo" style={{ color: "#004CBE" }}>
              SOLICITAR DEMO
            </a>
          </li>
          <li>
            <a href="#controles" style={{ color: "#004CBE" }}>
              {" "}
              PLATAFORMA DE CONTROLES
            </a>
          </li>
          <li>
            <a
              style={{ color: "#004CBE" }}
              href="https://api2.impesa.net/login?data=eyJjc3JmVG9rZW4iOiJUQkQiLCJhdWRpZW5jZSI6Imh0dHBzOi8vYXBpLmltcGVzYS5uZXQiLCJyZXNwb25zZV90eXBlIjoidG9rZW4iLCJyZWRpcmVjdF91cmkiOiJodHRwczovL2J1c2luZXNzLm1vbmlieXRlLmNvbS9hdXRob3JpemF0aW9uL2NhbGxiYWNrIiwiY29udGV4dCI6ImxvZ2luIiwiY2xpZW50X2lkIjoiYzdhNzBiNDM4NmI0NGMxOWFhZjgiLCJyZWZlcnJlciI6Imh0dHBzOi8vbW9uaWJ5dGUuY29tLyIsImNvbm5lY3Rpb25JZCI6ImFjZjZhMWE4MzY2ZTQ2YjQ4NDAzNzMzZjBkMWViYWUwIiwiZ3JhbnRfdHlwZSI6InBhc3N3b3JkIiwiYXBwbGljYXRpb25EYXRhIjp7Im5hbWVzcGFjZSI6ImJ1c2luZXNzIiwibWV0YWRhdGEiOnsidGl0bGUiOiJNb25pYnl0ZSB8IElNUEVTQSJ9fSwiY29ubmVjdGlvbkRhdGEiOnsicG9saWN5IjpudWxsLCJhbGxvd1Jlc2V0UGFzc3dvcmQiOnRydWV9fQ%3D%3D"
            >
              INICIAR SESIÓN
            </a>
          </li>
        </div>
      </header>

      <section class="main-section">
        <Grid container style={{ overflow: "hidden" }}>
          <Hidden smDown>
            <div
              style={{ backgroundImage: `url(${background})`, width: "100%" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="landing">
                  <div
                    style={{
                      position: "relative",
                      top: "20px",
                      color: "#004CBE",
                      fontWeight: "800",
                    }}
                  >
                    {" "}
                    <h4>Cada minuto de su tiempo es muy valioso, por eso:</h4>
                  </div>
                  <h1
                    style={{
                      fontSize: "40px",
                      color: "#004CBE",
                      fontWeight: "900",
                      position: "relative",
                      bottom: "25px",
                    }}
                  >
                    ¡Simplifique el control de gastos de<br></br> su empresa con
                    Lysto Empresas!
                  </h1>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "75px",
                      color: "#004CBE",
                      fontWeight: "400",
                      bottom: "30px",
                    }}
                  >
                    <div>
                      Enfóquese en resultados y deje el control de gastos en
                      nuestras manos
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    paddingTop: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button class="glow-on-hover" type="button">
                    <h3>
                      {" "}
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="#demo"
                      >
                        Obtener Demo
                      </a>
                    </h3>
                  </button>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    paddingTop: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      color: "black",
                      marginRight: "30px",
                      textDecoration: "none",
                      color: "#004CBE",
                    }}
                    href="mailto:willy.peraza@lystocard.com"
                  >
                    <h3>
                      {" "}
                      <GrMail /> hellob2b@lysto.com
                    </h3>
                  </a>

                  <SocialIcon  bgColor="#004CBE"
                    style={{ marginRight: "30px" }}
                    url="https://www.facebook.com/Lystocr-107011095590503"
                  />
                  <SocialIcon  bgColor="#004CBE" url="https://www.youtube.com/@lystocard" />

                  <SocialIcon  bgColor="#004CBE"
                    style={{ marginLeft: "30px" }}
                    url="https://www.instagram.com/lystocard/?hl=en"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    paddingTop: "20px",

                    textAlign: "center",
                  }}
                >
                  <img style={{ width: "84%" }} src={flujoHeader}></img>
                </div>
              </Grid>
            </div>

            <Grid
              container
              style={{ backgroundColor: "#FBFBFB", paddingBottom: "90px" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className="video-container">
                  <iframe
                    title="My Video"
                    src="https://www.youtube.com/embed/o6nIZY3KqN0"
                    allowFullScreen
                  ></iframe>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  className="titulo2"
                  style={{
                    position: "relative",
                    display: "grid",
                    paddingTop: "17%",
                    textAlign: "left",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    fontWeight: "400",
                  }}
                >
                  <div>
                    Una plataforma integral para la administración de sus gastos
                  </div>
                  <div style={{ fontWeight: "bolder" }}>
                    ¿Qué ofrece Lysto Empresas?
                  </div>
                  <br></br>
                  {/*   <ul>
                  <MdAddCard /> Tarjetas recargables{" "}
                  <img style={{ width: "50px" }} src={visa}></img>
                </ul> */}

                  <div>
                    <MdCreditCard /> Tarjetas de crédito*
                  </div>
                  <br></br>
                  <div>
                    <MdPublishedWithChanges /> Proceso de liquidación de gastos
                  </div>
                  <br></br>
                  <div>
                    <MdAttachMoney /> Integración simplificada a los principales
                    sistemas contables
                  </div>
                  <br></br>
                  <div>
                    <MdOutlineSavings /> Ahorre tiempo y dinero
                  </div>
                  <br></br>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  paddingTop: "3%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#343434",
                    fontSize: "32px",
                    fontWeight: "900",
                  }}
                >
                  Realice sus pagos de manera segura
                </div>{" "}
                <h4 style={{ color: "#606060", textAlign: "center" }}>
                  {" "}
                  Tarjetas crédito con respaldo global de{" "}
                  <img style={{ width: "50px" }} src={visa}></img>
                </h4>
              </div>
            </Grid>

            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  className="newBox"
                  style={{
                    position: "relative",
                    display: "flow",
                    padding: "20%",
                    backgroundImage: `url(${fondoFinal})`,
                    backgroundSize: "200%",
                    

                   
                  }}
                >
                  <img
                    style={{
                      transform: "rotate(350deg)",
                      width: "80%",
                      zIndex: 1,
                      position: "relative",
                    }}
                    src={lystoClassicB}
                  ></img>
                  <img
                    style={{ width: "80%", transform: "rotate(10deg)",zIndex: 4,
                    position: "relative", }}
                    src={lystoGoldB}
                  ></img>
                  <img
                    style={{
                      width: "80%",
                      transform: "rotate(-20deg)",
                      zIndex: 1,
                      position: "relative",
                      
                    }}
                    src={lystoInfiniteB}
                  ></img>
                  <img style={{ width: "80%" }} src={lystoPlatinumB}></img>

                  {/*  <div style={{ position: "relative", top: "50px" }}>
                    Tarjetas recargables{" "}
                    <img style={{ width: "50px" }} src={visa}></img>
                  </div> */}

                  <div
                    style={{
                      position: "relative",
                      paddingTop: "47px",
                      display: "inline-block",
                      margin: "0%",
                    }}
                  >
                    <button class="glow-on-hover" type="button" style={{filter: "drop-shadow(1px 1px 3px black)", }}>
                      <a
                        style={{ textDecoration: "none", color: "white"}}
                        href="#demo"
                      >
                        <h3>Más información</h3>
                      </a>
                    </button>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div
                  className="newBox"
                  style={{
                    position: "relative",
                    padding: "20%",
                    backgroundImage: `url(${fondoFinal})`,
                    backgroundSize: "200%",
                    maxWidth: "100%",
                  }}
                >
                  <div>
                    {" "}
                    <div
                      style={{
                        display: "grid",
                        position: "relative",

                        margin: "0 auto",
                      }}
                    >
                      <img
                        className="scaleCenter"
                        style={{ maxWidth: "30%" }}
                        src={viajes}
                      ></img>
                    </div>
                    <br></br>
                    <h4>Compras locales e internacionales</h4>{" "}
                  </div>

                  <div>
                    {" "}
                    <div
                      style={{
                        display: "grid",
                        position: "relative",
                        paddingTop: "px",
                        margin: "0 auto",
                      }}
                    >
                      <img
                        className="scaleCenter"
                        style={{ maxWidth: "30%" }}
                        src={shopping}
                      ></img>
                    </div>
                    <br></br>
                    <h4>Alta seguridad para compras en línea</h4>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "grid",
                        position: "relative",
                        paddingTop: "10px",
                        margin: "0 auto",
                      }}
                    >
                      <img
                        className="scaleCenter"
                        style={{ position: "relative", maxWidth: "30%" }}
                        src={controlComercio}
                      ></img>
                    </div>
                    <div>
                      <h4>
                        Asigne tarjetas a sus colaboradores para gastos
                        específicos
                      </h4>
                    </div>
                  </div>
                       <div style={{ position: "relative", paddingTop: "92px", bottom:"20px", filter: "drop-shadow(1px 1px 3px black)", }}>
                    <a
                      style={{ color: "black" }}
                      href="mailto:ventas@lystocard.com"
                    >
                      <button class="glow-on-hover" type="button">
                        <a
                          style={{ textDecoration: "none", color: "white" }}
                          href="#demo"
                        >
                          <h3>Solicitar tarjeta</h3>
                        </a>
                      </button>
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                id="controles"
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "900",
                  color: "#343434",
                  fontSize: "32px",
                }}
              >
                Control de gastos empresariales
              </div>
               <CookieConsent debug={false} buttonText="Acepto" declineButtonText="No acepto" expires={999} overlay enableDeclineButton

               buttonStyle={{
    background: "linear-gradient(to left, #5460d4 , #804fbc, #46b4fa)",
    color: "white",
    fontWeight: "bolder",
    textShadow: "1px 1px black",
  }}

  declineButtonStyle={{
    background: "linear-gradient(to left, #5460d4 , #804fbc, #46b4fa)",
    color: "white",
    fontWeight: "bolder",
    textShadow: "1px 1px black",
  }}

              onAccept={() => {

alert("Lysto, las cookies fueron aceptadas");}}

onDecline={() => {
    
    alert("Regresa cuando estés Lysto!");}}
 

>Este sitio utiliza cookies</CookieConsent>
            </Grid>

            <Grid container style={{ backgroundImage: `url(${fondoControl})` }}>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "137px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{ maxWidth: "12%" }}
                    src={bloqueo}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",
                    left:"8vw"

                    
                  }}
                >
                  <h4>
                    <div style={{position: "relative", top:"15px", color: "#004CBE" }}>Bloqueo y desbloqueo</div>
                    <br></br>
                    <div style={{position: "relative", fontSize:"14px" }}>Active sus tarjetas en tiempo real</div>
                  </h4>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{ maxWidth: "12%" }}
                    src={viajes}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",
                    left:"8vw",
                    
                    marginTop: "10px",
                  }}
                >
                  <h4>
                    <div style={{position: "relative", top:"15px", color: "#004CBE" }}> Control por país</div>{" "}
                    <br></br>
                    <div style={{position: "relative", fontSize:"14px" }}>Ingrese su destino y duración de estadía<br></br> para
                    viajar con seguridad</div>
                  </h4>
                </div>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{maxWidth: "12%"}}
                    src={limites}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",
                    left:"8vw"
                  }}
                >
                  <h4>
                    <div style={{color: "#004CBE"}}>
                      Límites personalizados
                    </div>{" "}
                    <br></br>
                    <div style={{position: "relative", bottom:"15px", fontSize:"14px" }}>Asigne límites diarios, semanales o<br></br> mensuales</div>
                  </h4>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",
                    margin: "0 auto",
                    paddingTop: "18px",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{ maxWidth: "12%" }}
                    src={controlComercio}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "120px",

                    
                  }}
                >
                  <h4>
                    <div style={{position: "relative", top:"15px", color: "#004CBE", left:"8vw" }}>
                      Control por comercios
                    
                    <br></br>
                    <div style={{position: "relative", fontSize:"14px", color:"black", top:"3px" }}>Defina límites por comercio</div></div>
                  </h4>
                </div>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "140px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{ maxWidth: "12%" }}
                    src={movil}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "135px",
                    
                  
                  }}
                >
                  <h4>
                    <div style={{position: "relative", top:"16px", color: "#004CBE", left:"8vw"  }}>
                      {" "}
                      Autogestión en línea
                    <br></br>
                    <div style={{color:"black", position:"relative", top:"3px", fontSize:"14px"}}>Aplique controles en tiempo<br></br> real desde su
                    computadora</div></div>
                  </h4>
                </div>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "160px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{ maxWidth: "12%" }}
                    src={shopping}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "145px",
                   
                  }}
                >
                  <h4>
                    <div style={{color:"#004CBE", position: "relative", top:"20px",  left:"8vw" }}>Compras en línea
                    <br></br>
                    <div style={{color:"black", position:"relative", top:"3px", fontSize:"14px"}}>Autorice compras en línea para evadir<br></br>fraude
                    cibernético</div></div>
                  </h4>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "136px",
                    margin: "0 auto",
                  }}
                >
                  <img
                    className="scaleCenter"
                    style={{ maxWidth: "12%" }}
                    src={seguridad}
                  ></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "116px",
                    
                    marginBottom: "30px",
                  }}
                >
                  <h4>
                    <div style={{ color: "#004CBE", position: "relative", top:"15px", left:"8vw" }}>Mayor seguridad
                    <br></br>
                    <div style={{color:"black", fontSize:"14px"}}>Bloquee su tarjeta en tiempo real<br></br> en caso de robo o
                    extravío</div></div>
                  </h4>
                </div>

                <div
                  style={{
                    position: "relative",
                    paddingTop: "150px",
                    left: "30%",
                  }}
                >
                  <img style={{ maxWidth: "90%" }} src={laptop}></img>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    top: "50px",
                    position: "relative",
                    fontWeight: "900",
                    color: "#343434",
                    fontSize: "32px",
                  }}
                >
                  {" "}
                  Automatice sus liquidaciones de gastos
                </div>

                <div
                  style={{
                    top: "40px",
                    position: "relative",
                    fontWeight: "900",
                    color: "#343434",
                    fontSize: "32px",
                  }}
                >
                  Elimine papeles y procesos manuales
                </div>

                <img style={{ width: "100%" }} src={controlGastos}></img>

                <div
                  style={{
                    position: "relative",
                    bottom: "70px",
                    color: "#004CBE",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Enfoque su tiempo en resultados y recupere la disponibilidad
                  de su equipo
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  paddingTop: "60px",
                  backgroundImage: `url(${fondoFinal})`,
                  backgroundSize: "900px",
                  paddingBottom: "60px",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "800",
                  }}
                >
                  Lysto Empresas se integra a su<br></br>
                  sistema contable de preferencia<br></br>
                </div>
                <br></br>
                <div style={{ color: "#606060" }}>
                  Simplifique el registro de los gastos en su sistema contable
                </div>
                <br></br>
                <div style={{ color: "#606060", fontSize: "12px" }}>
                  *Lysto Empresas ofrece líneas de crédito a través del
                  partner Lysto Card.
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                id="demo"
                className="datos"
                style={{
                  position: "relative",
                  paddingBottom: "10%",
                  display: "grid",

                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "#5460d4",
                  backgroundPosition: "200px",
                  maxWidth: "100%",
                }}
              >
                <div class="login-box" style={{ overflow: "hidden" }}>
                  <h2
                    style={{
                      position: "relative",
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                  >
                    Descubra el potencial que Lysto Empresas<br></br>
                    pueda darle a su empresa
                  </h2>
                  <form
                    id="main-contact-form"
                    className="contactForm"
                    name="contact-form"
                    method="post"
                    action="mail.php"
                    enctype="multipart/form-data"
                  >
                    <div class="user-box">
                      <input
                        type="text"
                        name="brandName"
                        class="form-control"
                        required="required"
                      />
                      <label>Nombre completo</label>
                    </div>
                    <div class="user-box">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        required="required"
                      />
                      <label>Email</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="country"
                        class="form-control"
                        required="required"
                      />
                      <label>Teléfono</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="id"
                        class="form-control"
                        required="required"
                      />
                      <label>Compañía</label>
                    </div>
                    <div class="user-box">
                      <input
                        type="text"
                        name="direccion"
                        class="form-control"
                        required="required"
                      />
                      <label>Dirección</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="empleados"
                        class="form-control"
                        required="required"
                      />
                      <label>Cantidad de empleados</label>
                    </div>
                    <a href="#">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>

                      <div class="form-group">
                        <Button
                          variant="outlined"
                          type="submit"
                          name="submit"
                          required="required"
                          style={{ color: "white", fontWeight: "bolder" }}
                        >
                          ¡Enviar!
                        </Button>{" "}
                      </div>
                    </a>
                  </form>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="container">
                <h4>FAQ</h4>
                <Accordion
                  style={{
                    width: 700,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                    >
                      ¿Cómo puedo comenzar a trabajar con Lysto Empresas?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography    style={{
                        fontWeight:"bold"
                      }}>
                      Complete la información de contacto y lo contactaremos. Lo
                      guiaremos durante la apertura de la cuenta y la
                      implementación.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 700,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Es necesario una instalación para la implementación de
                      Lysto Empresas?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography    style={{
                        fontWeight:"bold"
                      }}>
                      No, solo es necesario acceder a lystocard.com. Somos una
                      plataforma virtual, solo se necesita internet.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 700,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Cuál es el costo?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography    style={{
                        fontWeight:"bold"
                      }}>
                      No hay costo. Es gratuito ingresar a la plataforma,
                      registrarse, y solicitar la tarjeta de sus colaboradores.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 700,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Cuál es la comisión al utilizar la tarjeta?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography    style={{
                        fontWeight:"bold"
                      }}>
                      Creemos en la transparencia. Le compartimos las comisiones
                      al utilizar la tarjeta Lysto. Retiro en cajeros
                      automáticos: 2.5% del monto retirado.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 700,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Quien administra Lysto Empresas?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography    style={{
                        fontWeight:"bold"
                      }}>
                      Al registrarse en Lysto Empresas, se crea una cuenta
                      junto al usuario administrador. Se asigna una persona que
                      tendrá acceso a toda la información. Existen diferentes
                      perfiles con diferentes permisos. Por ejemplo,
                      subadministrador y adicional.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 700,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Lysto Empresas funciona en dólares y colones?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography    style={{
                        fontWeight:"bold"
                      }}>
                      Sí, ofrecemos tarjetas en dólares y colones.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>

            <Grid
              container
              style={{
                position: "relative",
                paddingTop: "30px",
                paddingBottom: "30px",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <img
                  style={{
                    maxWidth: "80%",
                    position: "relative",
                    marginTop: "10px",
                    left: "10px",
                    display: "flex",
                    /*  filter: "drop-shadow(1px 1px 2px black)", */
                  }}
                  src={logoMoni}
                ></img>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                    display: "grid",
                    padding: "20px",
                  }}
                >
                  <h3>Contacto</h3>

                  <a
                    style={{ textDecoration: "none", display: "grid" }}
                    href="mailto:willy.peraza@lystocard.com"
                  >
                    hellob2b@lysto.com
                  </a>
                  <br></br>
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href="tel:+50640100808"
                  >
                    {" "}
                    Teléfono
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    position: "relative",
                  }}
                >
                  <h3> Dirección</h3>
                  Escazú, de la plaza Riverside, 250 m este carretera a, bello
                  Horizonte, San José Province, Escazú
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                    top: "21%",
                    position: "relative",
                    padding: "40px",
                  }}
                >
                  <div>
                    {" "}
                    “Lysto Empresas es propiedad intelectual de Lysto”
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    position: "relative",
                  }}
                >
                  <h3> Sobre LYSTO</h3>
                  <a
                    style={{ textDecoration: "none", color: "#004cbe" }}
                    href="https://www.lystocard.com/"
                  >
                    <h3>LYSTO</h3>
                  </a>
                </div>
              </Grid>
              <Grid
                style={{ position: "relative", top: "20px", padding: "20px" }}
                item
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
              >
                <div>
                  {" "}
                  <img
                    style={{ position: "relative", left: "50px", width: "38%" }}
                    src={visa}
                  ></img>
                </div>
                <div style={{ position: "relative", top: "10%" }}>
                  Nuestras tarjetas son marca{" "}
                  <img style={{ maxWidth: "20%" }} src={visa}></img> y cuentan
                  con su respaldo global
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{ position: "relative", top: "30px" }}
                  className="App-credits"
                >
                  Comma Pump Development®
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        {/*mobil presentation*/}
        {/*mobil presentation*/}
        {/*mobil presentation*/}
        {/*mobil presentation*/}
        {/*mobil presentation*/}
        {/*mobil presentation*/}

        <Grid container style={{ overflow: "hidden" }}>
          <Hidden smUp>
            <Grid
              container
              style={{ backgroundImage: `url(${backgroundMobile})` }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  className="landing"
                  style={{
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      bottom: "15px",

                      color: "#004CBE",
                      fontWeight: "700",
                      fontSize: "11px",
                    }}
                  >
                    {" "}
                    <div>Cada minuto de su tiempo es muy valioso, por eso:</div>
                  </div>
                  <h1
                    style={{
                      fontSize: "19px",
                      color: "#004CBE",
                      fontWeight: "900",
                    }}
                  >
                    ¡Simplifique el control de gastos de su empresa con Lysto
                    Empresas!
                  </h1>
                  <div
                    style={{
                      position: "relative",
                      paddingBottom: "30px",
                      color: "#004CBE",
                      fontSize: "12px",
                      color: "#343434",
                      top: "20px",
                    }}
                  >
                    <div>
                      Enfóquese en resultados y deje el control de gastos en
                      <br></br>
                      nuestras manos
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    paddingTop: "37%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button class="glow-on-hover" type="button">
                    <h3>
                      {" "}
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="#demo"
                      >
                        Obtener Demo
                      </a>
                    </h3>
                  </button>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    top: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{
                      color: "#004CBE",
                      fontSize: "17px",

                      textDecoration: "none",
                    }}
                    href="mailto:willy.peraza@lystocard.com"
                  >
                    <h4>
                      {" "}
                      <GrMail /> hellob2b@lysto.com
                    </h4>
                  </a>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    paddingTop: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SocialIcon bgColor="#004CBE"
                    style={{ marginRight: "30px" }}
                    url="https://www.facebook.com/Lystocr-107011095590503"
                  />
                  <SocialIcon  bgColor="#004CBE" url="https://www.youtube.com/channel/UC9Bx4TdpS8r0aRIWbo8rWYg" />

                  <SocialIcon bgColor="#004CBE"
                    style={{ marginLeft: "30px" }}
                    url="https://www.instagram.com/lystocard/?hl=en"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "18px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{ maxWidth: "60%" }} src={flujoHeaderMobile}></img>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="video-containerMobile">
                <iframe
                  title="My Video"
                  src="https://www.youtube.com/embed/o6nIZY3KqN0"
                  allowFullScreen
                ></iframe>
              </div>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="video-container" autoplay style={{ position: "relative",
  paddingTop: "250px",
  display: "block",
 }}> 

             
               
                  <video controls width="100%" >
                    <source src={video} type="video/mp4" />
                  </video>
                 
               
              </div>
            </Grid> */}

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{ paddingTop: "200px" }}
            >
              <div
                className="titulo2"
                style={{
                  position: "relative",
                  display: "grid",
                  textAlign: "center",
                  backgroundColor: "#FBFBFB",
                  fontWeight: "300",
                }}
              >
                <h5 style={{ color: "#004CBE" }}>
                  Una plataforma integral para la administración<br></br> de sus
                  gastos
                </h5>

                <h5
                  style={{
                    color: "black",
                    position: "relative",
                    bottom: "30px",
                  }}
                >
                  ¿Qué ofrece Lysto Empresas?
                </h5>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                className="titulo2"
                style={{
                  position: "relative",
                  display: "grid",
                  backgroundColor: "#FBFBFB",

                  textAlign: "center",
                }}
              >
                {/*   <div
                  style={{
                    display: "grid",
                    position: "relative",

                    margin: "0 auto",
                  }}
                >
                  <img src={tarjeta}></img>
                </div>
                 <div>
                  Tarjetas recargables{" "}
                  <img style={{ width: "50px" }} src={visa}></img>
                </div>
                <br></br> */}
                <div
                  style={{
                    display: "grid",
                    position: "relative",

                    margin: "0 auto",
                  }}
                >
                  <img style={{ width: "50px" }} src={plataforma}></img>
                </div>{" "}
                <br></br>
                <div style={{ fontSize: "14px" }}>Tarjetas de crédito*</div>
                <br></br>
                <div
                  style={{
                    display: "grid",
                    position: "relative",

                    margin: "0 auto",
                  }}
                >
                  <img style={{ width: "50px" }} src={procesos}></img>
                </div>{" "}
                <br></br>
                <div style={{ fontSize: "14px" }}>
                  Proceso de liquidación de gastos
                </div>
                <br></br>
                <div
                  style={{
                    display: "grid",
                    position: "relative",

                    margin: "0 auto",
                  }}
                >
                  <img style={{ width: "50px" }} src={integracion}></img>
                </div>{" "}
                <br></br>
                <div style={{ fontSize: "14px" }}>
                  Integración simplificada a los principales<br></br> sistemas
                  contables
                </div>
                <br></br>
                <div
                  style={{
                    display: "grid",
                    position: "relative",

                    margin: "0 auto",
                  }}
                >
                  <img style={{ width: "50px" }} src={control}></img>
                </div>{" "}
                <br></br>
                <div style={{ fontSize: "14px" }}>Ahorre tiempo y dinero</div>
                <br></br>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",

                  textAlign: "center",
                  padding: "40px",
                }}
              >
                <div
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "900",
                  }}
                >
                  Realice sus pagos de manera segura
                </div>
                <br></br>
                <div>
                  {" "}
                  Tarjetas de crédito con<br></br> respaldo global de{" "}
                  <img style={{ width: "50px" }} src={visa}></img>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="newBox"
                style={{
                  position: "relative",
                  textAlign: "center",
                  padding: "20%",
                  backgroundImage: `url(${fondoFinal})`,
                  backgroundSize: "140%",

                  
                }}
              >
                <img
                  style={{
                    transform: "rotate(340deg)",
                    width: "100%",
                    zIndex: "2",
                  }}
                  src={lystoClassicB}
                ></img>
                <img
                  style={{ transform: "rotate(10deg)", width: "100%",  position: "relative",
                  zIndex: 5 }}
                  src={lystoGoldB}
                ></img>
                <img
                  style={{
                    transform: "rotate(-20deg)",
                    width: "100%",
                    position: "relative",
                    zIndex: 4,
                  }}
                  src={lystoInfiniteB}
                ></img>
                <img style={{ width: "100%" }} src={lystoPlatinumB}></img>

                {/*   <div style={{ position: "relative", top: "50px" }}>
                  Tarjetas recargables{" "}
                  <img style={{ width: "50px" }} src={visa}></img>
                </div> */}

                <div
                  style={{
                    position: "relative",
                    paddingTop: "60px",
                    display: "inline-block",

                    margin: "0%",
                  }}
                >
                  <button class="glow-on-hover" type="button">
                    <a
                      style={{ textDecoration: "none", color: "white",filter: "drop-shadow(1px 1px 4px black)", }}
                      href="#demo"
                    >
                      <h4>Más información</h4>
                    </a>
                  </button>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                className="newBox"
                style={{
                  position: "relative",
                  padding: "20%",
                  backgroundImage: `url(${fondoFinal})`,
                  backgroundSize: "140%",
                }}
              >
                <div style={{ fontSize: "12px" }}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      alignItems: "center",
                    }}
                  >
                    <img style={{ maxWidth: "50%" }} src={viajes}></img>
                  </div>
                  <h4>Compras locales e internacionales</h4>{" "}
                </div>
                <br></br>

                <div style={{ fontSize: "12px" }}>
                  {" "}
                  <div
                    style={{
                      display: "flex",

                      top: "12px",
                      alignItems: "center",
                    }}
                  >
                    <img style={{ maxWidth: "50%" }} src={shopping}></img>
                  </div>
                  <br></br>
                  <h4> Alta seguridad para compras en línea</h4>
                </div>
                <br></br>

                <div style={{ fontSize: "12px" }}>
                  <div
                    style={{
                      display: "grid",
                      position: "relative",
                      top: "20px",
                      margin: "0 0",
                    }}
                  >
                    <img
                      style={{ maxWidth: "50%" }}
                      src={controlComercio}
                    ></img>
                  </div>
                  <br></br>
                  <br></br>

                  <h4>
                    {" "}
                    Asigne tarjetas a sus colaboradores para gastos específicos
                  </h4>
                </div>
                <br></br>
                  <div style={{ position: "relative"}}>
                  <a
                    style={{ color: "black" }}
                    href="mailto:ventas@lystocard.com"
                  >
                    <button class="glow-on-hover" type="button">
                      <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="#demo"
                      >
                        <h4>Solicitar tarjeta</h4>
                      </a>
                    </button>
                  </a>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                id="controles"
                style={{
                  display: "grid",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  top: "60px",
                  fontSize: "20px",
                  fontWeight: "900",
                }}
              >
                Control de gastos empresariales
              </div>
            </Grid>

            <Grid
              style={{ backgroundImage: `url(${fondoIntegra})` }}
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <div
                style={{
                  padding: "30px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "100px",
                    marginBottom: "30px",
                  }}
                >
                  <img style={{ maxWidth: "45px" }} src={bloqueo}></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "100px",
                    marginBottom: "30px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ color: "#004CBE" }}>Bloqueo y desbloqueo</div>{" "}
                  <br></br>
                  Active sus tarjetas en tiempo real
                </div>
                <br></br>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "100px",
                  }}
                >
                  <img style={{ maxWidth: "45px" }} src={viajes}></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "100px",
                    textAlign: "center",
                    marginTop: "30px",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ color: "#004CBE" }}>Control por país</div>{" "}
                  <br></br>
                  Ingrese su destino y duración de estadía<br></br> para viajar
                  con seguridad
                </div>
                <br></br>
                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "100px",
                    marginBottom: "30px",
                  }}
                >
                  <img style={{ maxWidth: "45px" }} src={limites}></img>
                </div>

                <div
                  style={{
                    display: "grid",
                    position: "relative",
                    top: "100px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  <div style={{ color: "#004CBE" }}>Límites personalizados</div>{" "}
                  <br></br>
                  Asigne límites diarios, semanales o<br></br>mensuales
                </div>
                <br></br>
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  marginBottom: "30px",
                }}
              >
                <img style={{ maxWidth: "45px" }} src={controlComercio}></img>
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  marginBottom: "30px",
                  fontSize: "14px",

                  textAlign: "center",
                }}
              >
                <div style={{ color: "#004CBE" }}>Control por comercios</div>
                <br></br>
                Defina límites por comercio
              </div>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  marginBottom: "30px",
                }}
              >
                <img style={{ maxWidth: "45px" }} src={movil}></img>
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  textAlign: "center",
                  marginBottom: "30px",
                  fontSize: "14px",
                }}
              >
                <div style={{ color: "#004CBE" }}>Autogestión en línea</div>
                <br></br>
                Aplique controles en tiempo real<br></br> desde su computadora
              </div>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  marginBottom: "30px",
                }}
              >
                <img style={{ maxWidth: "45px" }} src={shopping}></img>
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  textAlign: "center",
                  marginBottom: "30px",
                  fontSize: "14px",
                }}
              >
                <div style={{ color: "#004CBE" }}>Compras en línea</div>
                <br></br>
                Autorice compras en línea para<br></br> evadir fraude
                cibernético
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  marginBottom: "30px",
                }}
              >
                <img style={{ maxWidth: "45px" }} src={seguridad}></img>
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "100px",
                  textAlign: "center",
                  marginBottom: "30px",
                  fontSize: "14px",
                }}
              >
                <div style={{ color: "#004CBE" }}>Mayor seguridad</div>
                <br></br>
                Bloquee su tarjeta en tiempo real<br></br> en caso de robo o
                extravío
              </div>

              <div
                style={{
                  display: "flex",
                  position: "relative",
                  paddingTop: "150px",
                  left: "50px",
                }}
              >
                <img style={{ maxWidth: "80%" }} src={laptop}></img>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",

                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    top: "40px",
                    position: "relative",
                    fontSize: "20px",
                    fontWeight: "900",
                  }}
                >
                  Automatice sus liquidaciones<br></br> de gastos
                </div>
                <br></br>
                <div
                  style={{
                    paddingTop: "40px",
                    position: "relative",
                    fontSize: "20px",
                    fontWeight: "900",
                  }}
                >
                  Elimine papeles y procesos manuales
                </div>
              </div>

              <div
                style={{
                  display: "grid",
                  position: "relative",
                  fontWeight: "lighter",
                  color: "#606060",

                  textAlign: "center",
                }}
              >
                <h5>
                  Digitalice el control de gastos para<br></br>ahorrar tiempo y
                  dinero
                </h5>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                }}
              >
                <img
                  style={{ maxWidth: "90%" }}
                  src={controlGastosMobile}
                ></img>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  padding: "20px",
                  textAlign: "center",
                  color: "#004CBE",
                  bottom: "60px",
                }}
              >
                {" "}
                Enfoque su tiempo en resultados y recupere la disponibilidad de
                su equipo
              </div>
            </Grid>

            <Grid
              style={{
                backgroundImage: `url(${fondoFinal})`,
                backgroundSize: "500px",
              }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <div
                style={{
                  display: "grid",
                  position: "relative",
                  top: "20px",

                  textAlign: "center",
                }}
              >
                <div style={{ fontSize: "20px", fontWeight: "800" }}>
                  Lysto Empresas<br></br> se integra a su sistema<br></br>{" "}
                  contable de preferencia
                </div>
                <h5
                  style={{
                    bottom: "24px",
                    position: "relative",
                    padding: "20px",
                    color: "#606060",
                  }}
                >
                  Simplifique el registro de los<br></br> gastos en su sistema
                  contable
                </h5>
                <div
                  style={{
                    fontSize: "12px",
                    padding: "20px",
                    position: "relative",
                    bottom: "70px",
                    color: "#606060",
                  }}
                >
                  *Lysto Empresas ofrece líneas de crédito <br></br> a
                  través del partner Lysto Card.
                </div>
              </div>
              
              <CookieConsent
                debug={false}
                buttonText="Acepto"
                declineButtonText="No acepto"
                expires={999}
                overlay
                enableDeclineButton
                buttonStyle={{
                  background:
                    "linear-gradient(to left, #5460d4 , #804fbc, #46b4fa)",
                  color: "white",
                  fontWeight: "bolder",
                  textShadow: "1px 1px black",
                }}
                declineButtonStyle={{
                  background:
                    "linear-gradient(to left, #5460d4 , #804fbc, #46b4fa)",
                  color: "white",
                  fontWeight: "bolder",
                  textShadow: "1px 1px black",
                }}
                onAccept={() => {
                  alert("Lysto, las cookies fueron aceptadas");
                }}
                onDecline={() => {
                  alert("Regresa cuando estés Lysto!");
                }}
              >
                Este sitio utiliza cookies
              </CookieConsent>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div
                id="demo"
                className="datos"
                style={{
                  position: "relative",
                  paddingBottom: "40%",
                  display: "grid",

                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "#5460d4",
                  backgroundPosition: "200px",
                  maxWidth: "100%",
                }}
              >
                <div class="login-boxMobile" style={{ overflow: "hidden" }}>
                  <h2
                    style={{
                      position: "relative",
                      textAlign: "center",
                      fontSize: "25px",
                    }}
                  >
                    Descubra el potencial de Lysto Empresas
                  </h2>
                  <form
                    id="main-contact-form"
                    className="contactForm"
                    name="contact-form"
                    method="post"
                    action="mail.php"
                    enctype="multipart/form-data"
                  >
                    <div class="user-box">
                      <input
                        type="text"
                        name="brandName"
                        class="form-control"
                        required="required"
                      />
                      <label>Nombre completo</label>
                    </div>
                    <div class="user-box">
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        required="required"
                      />
                      <label>Email</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="country"
                        class="form-control"
                        required="required"
                      />
                      <label>Teléfono</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="id"
                        class="form-control"
                        required="required"
                      />
                      <label>Compañía</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="direccion"
                        class="form-control"
                        required="required"
                      />
                      <label>Dirección</label>
                    </div>

                    <div class="user-box">
                      <input
                        type="text"
                        name="empleados"
                        class="form-control"
                        required="required"
                      />
                      <label>Cantidad de empleados</label>
                    </div>
                    <a href="#">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>

                      <div class="form-group">
                        <Button
                          variant="outlined"
                          type="submit"
                          name="submit"
                          required="required"
                          style={{ color: "white", fontWeight: "bolder" }}
                        >
                          ¡Enviar!
                        </Button>{" "}
                      </div>
                    </a>
                  </form>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="containerMobile">
                <h4
                  style={{
                    textAlign: "center",
                  }}
                >
                  FAQ
                </h4>
                <Accordion
                  style={{
                    width: 370,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Cómo puedo comenzar a trabajar con Lysto Empresas?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Complete la información de contacto y lo contactaremos. Lo
                      guiaremos durante la apertura de la cuenta y la
                      implementación.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 370,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Es necesario una instalación para la implementación de
                      Lysto Empresas?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      No, solo es necesario acceder a lystocard.com. Somos una
                      plataforma virtual, solo se necesita internet.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 370,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Cuál es el costo?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      No hay costo. Es gratuito ingresar a la plataforma,
                      registrarse, y solicitar la tarjeta de sus colaboradores.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 370,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Cuál es la comisión al utilizar la tarjeta?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Creemos en la transparencia. Le compartimos las comisiones
                      al utilizar la tarjeta Lysto. Retiro en cajeros
                      automáticos: 2.5% del monto retirado.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 370,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Quien administra Lysto Empresas?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Al registrarse en Lysto Empresas, se crea una cuenta
                      junto al usuario administrador. Se asigna una persona que
                      tendrá acceso a toda la información. Existen diferentes
                      perfiles con diferentes permisos. Por ejemplo,
                      subadministrador y adicional.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  style={{
                    width: 370,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                  >
                    <Typography
                      style={{
                        fontWeight: 10,
                      }}
                    >
                      ¿Lysto Empresas funciona en dólares y colones?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Sí, ofrecemos tarjetas en dólares y colones.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>

            <Grid
              container
              style={{
                position: "relative",
                paddingTop: "30px",
                paddingBottom: "30px",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <img
                  style={{
                    maxWidth: "250px",
                    position: "relative",
                    marginTop: "20px",
               /*      filter: "drop-shadow(2px 2px 3px black)", */
                    display: "flex",
                  }}
                  src={logoMoni}
                ></img>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h3>Contacto</h3>

                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:willy.peraza@lystocard.com"
                  >
                    hellob2b@lysto.com
                  </a>
                  <br></br>
                  <br></br>
                  <a
                    style={{ color: "black", textDecoration: "none" }}
                    href="tel:+50640100808"
                  >
                    {" "}
                    Teléfono
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  <h3> Dirección</h3>
                  Escazú, de la plaza Riverside, 250 m este carretera a, bello
                  Horizonte, San José Province, Escazú
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h4>
                    {" "}
                    “Lysto Empresas es propiedad intelectual de Lysto”
                  </h4>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <h3> Sobre LYSTO</h3>
                  <a
                    style={{ textDecoration: "none", color: "#004cbe" }}
                    href="https://www.lystocard.com/"
                  >
                    <h3>LYSTO</h3>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <div style={{ textAlign: "center", padding: "20px" }}>
                  Nuestras tarjetas son marca{" "}
                  <img style={{ width: "8%" }} src={visa}></img> y cuentan con
                  su respaldo global
                </div>

              
                <img
                  style={{
                    justifyContent: "center",
                    width: "15%",
                    display: "flex",
                    paddingTop: "15px",
                  }}
                  src={visa}
                ></img>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div
                  style={{ position: "relative", top: "30px" }}
                  className="App-credits"
                >
                  Comma Pump Development®
                </div>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </section>
    </>
  );
};
export default Home;
