import React from 'react'
import './App.css'
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from "./pages/About";
import Products from './pages/Products';
import Blog from './pages/Blog';


function App() {
  return (
    <>          
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
      </BrowserRouter>
    </>
  )
}
export default App
























/* import logo from "./logo.svg";
import "./App.css";
import Grid from "@mui/material/Grid";
import logoMoni from "./images/logo.png";
import React from "react";
import { Link } from "react-router-dom";

function App() {
  return (
    <Grid container>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        
          <nav>
            <ul>
              <img style={{ maxWidth: "250px" }} src={logoMoni}></img>

            </ul>
          </nav>
    
      </Grid>
      <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
        <div className="navbar">
          <nav>
            <ul>
           
              SOLICITAR DEMO PLATAFORMA DE CONTROLES INICIAR SESIÓN
            </ul>
          </nav>
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="landing">
         <h4>Cada minuto de su tiempo es muy valioso, por eso:</h4>
          <h2>¡Simplifique el control de gastos de<br></br> su empresa con Monibyte for Business!</h2>
          <h4>Enfóquese en resultados y deje el control de gastos en nuestras manos</h4>
        </div>
      </Grid>
    </Grid>
  );
}

export default App; */
